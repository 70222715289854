<p-accordion [multiple]="true">
  <p-accordionTab header="{{'REVIEW_VENDOR_RESPONSE' | translate}}" [selected]="true">
    <div class="col-md-12 d-flex flex-row-reverse" id="liquid-bulk-details" style="padding-right:0px"
      *ngIf="!vendorUser && requestData.status!=='Bid Finalized'">
      <button style="margin-left: 1rem;" *ngIf="!disableEditMode" class="btn btn-sm btn-primary"
        (click)="updateVendors()">{{'VENDOR_GRID.UPDATE_VENDOR' | translate}}</button>
      <button class="btn btn-sm btn-outline-primary" *ngIf="!disableEditMode" [disabled]="!selectedVendors.length"
        (click)="notifyVendors()">{{'VENDOR_GRID.NOTIFY_VENDOR' | translate}}</button>
    </div>
    <div class="vendorDiv" id="userTableLiquidBulkDetail" style="margin-top: 10px; margin-bottom: 10px">
      <p-table [value]="vendorsData" [(selection)]="selectedVendors" selectionMode="multiple" [scrollable]="true"
        [style]="{width:'100%'}" dataKey="vendorId" [autoLayout]="true">
        <ng-template pTemplate="header">
          <tr *ngIf="vendorUser">
            <th id="vendor" style="width:200px">{{'VENDOR_GRID.VENDOR' | translate}}</th>
            <th id="vendorUsers" style="width:200px">{{'VENDOR_GRID.VENDOR_USERS' | translate}}</th>
            <th id="rate" style="width:200px" *ngIf="requestData.status!=='BU Submitted'">{{rate1_Label | translate}}
            </th>
            <th id="cleaningcost1" style="width:200px" *ngIf="naMexicoCountry && requestData.status!=='BU Submitted'">
              {{'VENDOR_GRID.CLENING_COST1' | translate}}</th>
            <th id="payLoadinTons1" *ngIf="requestData.status!=='BU Submitted' && selectedRegion =='EMEA'"
              style="width:300px">Pay Load (Tons)</th>
            <th id="leadTimeinDays1" *ngIf="requestData.status!=='BU Submitted' && selectedRegion =='EMEA'"
              style="width:300px">Lead Time (Days)</th>

            <th id="selection" style="width:80px" *ngIf="requestData.status=='Bid Finalized'">{{'VENDOR_GRID.AWARDED' |
              translate}}</th>
            <th id="rank" style="width:140px" *ngIf="requestData.status=='Bid Finalized'">{{'VENDOR_GRID.RANK' |
              translate}}</th>
            <th id="rate2" style="width:200px" *ngIf="requestData.status!=='BU Submitted'">{{rate2_Label | translate}}
            </th>
            <th id="cleaningcost2" style="width:200px" *ngIf="naMexicoCountry && requestData.status!=='BU Submitted'">
              {{'VENDOR_GRID.CLENING_COST2' | translate}}</th>
            <th id="payLoadinTons2" *ngIf="requestData.status!=='BU Submitted'  && selectedRegion =='EMEA'"
              style="width:300px">Pay Load (Tons)</th>
            <th id="leadTimeinDays2" *ngIf="requestData.status!=='BU Submitted' && selectedRegion =='EMEA'"
              style="width:300px">Lead Time (Days)</th>

            <th id="selection2" style="width:80px" *ngIf="requestData.status=='Bid Finalized'">{{'VENDOR_GRID.AWARDED' |
              translate}}</th>
            <th id="rank2" style="width:140px" *ngIf="requestData.status=='Bid Finalized'">{{'VENDOR_GRID.RANK' |
              translate}}</th>
            <th id="duedate" style="width:160px">{{'VENDOR_GRID.DUE_DATE' | translate}}</th>
            <th id="comments" style="width:300px">{{'COMMENTS' | translate}}</th>
            <th id="attachment" style="width:200px">{{'VENDOR_GRID.ATTACHMENT' | translate}}</th>
            <th id="history" style="width:100px">{{'VENDOR_GRID.HISTORY' | translate}}</th>

          </tr>

          <tr *ngIf="!vendorUser">
            <th id="checkbox" *ngIf="requestData.status!=='Bid Finalized'" style="width:30px">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th id="vendor" style="width:200px">{{'VENDOR_GRID.VENDOR' | translate}}</th>
            <th id="vendorUsers" style="width:200px">{{'VENDOR_GRID.VENDOR_USERS' | translate}}</th>
            <th id="emailstatus" style="width:50px"></th>
            <th id="rate" style="width:200px" *ngIf="requestData.status!=='BU Submitted'">{{rate1_Label | translate}}
            </th>
            <th id="cleaningcost1" style="width:200px" *ngIf="naMexicoCountry && requestData.status!=='BU Submitted'">
              {{'VENDOR_GRID.CLENING_COST1' | translate}}</th>
            <th id="payLoadinTons1" *ngIf="requestData.status!=='BU Submitted' && selectedRegion =='EMEA'"
              style="width:200px">Pay Load (Tons)</th>
            <th id="leadTimeinDays1" *ngIf="requestData.status!=='BU Submitted' && selectedRegion =='EMEA'"
              style="width:200px">Lead Time (Days)</th>

            <th id="selection" style="width:80px" *ngIf="requestData.status!=='BU Submitted'">{{'VENDOR_GRID.AWARDED' |
              translate}}</th>
            <th id="rank" style="width:140px" *ngIf="requestData.status!=='BU Submitted'">{{'VENDOR_GRID.RANK' |
              translate}}</th>
            <th id="rate2" style="width:200px" *ngIf="requestData.status!=='BU Submitted'">{{rate2_Label | translate}}
            </th>
            <th id="cleaningcost2" style="width:200px" *ngIf="naMexicoCountry && requestData.status!=='BU Submitted'">
              {{'VENDOR_GRID.CLENING_COST2' | translate}}</th>
            <th id="payLoadinTons2" *ngIf="requestData.status!=='BU Submitted' && selectedRegion =='EMEA'"
              style="width:200px">Pay Load (Tons)</th>
            <th id="leadTimeinDays2" *ngIf="requestData.status!=='BU Submitted'  && selectedRegion =='EMEA'"
              style="width:200px">Lead Time (Days)</th>

            <th id="selection2" style="width:80px" *ngIf="requestData.status!=='BU Submitted'">{{'VENDOR_GRID.AWARDED' |
              translate}}</th>
            <th id="rank2" style="width:140px" *ngIf="requestData.status!=='BU Submitted'">{{'VENDOR_GRID.RANK' |
              translate}}</th>
            <th id="duedate" style="width:160px">{{'VENDOR_GRID.DUE_DATE' | translate}}</th>
            <th id="comments" style="width:300px" *ngIf="requestData.status!=='BU Submitted'">{{'COMMENTS' | translate}}
            </th>
            <th id="attachment" style="width:200px">{{'VENDOR_GRID.ATTACHMENT' | translate}}</th>
            <th id="history" style="width:100px">{{'VENDOR_GRID.HISTORY' | translate}}</th>
            <th id="btn1" *ngIf="requestData.status!=='Bid Finalized' && !disableEditMode"
              style="text-align:center;width:150px;position:sticky"> {{'VENDOR_GRID.ADD_REMOVE' | translate}}</th>


          </tr>

        </ng-template>


        <ng-template pTemplate="body" let-rowData let-ri="rowIndex" *ngIf="vendorUser">
          <tr>

            <td style="width:200px">
              <div>
                {{rowData.vendorName}}
              </div>
            </td>

            <td style="width:200px">
              <div pTooltip="{{rowData.selectedVendorNames}}">
                <div *ngFor="let user of rowData.selectedVendorUser;index as i">
                  <span *ngIf="i<2">
                    <label htmlFor="user.Name">{{user.Name}}</label>
                    <span *ngIf="rowData.selectedVendorUser.length!==i+1">,</span>
                  </span>
                </div>
                <span *ngIf="rowData.selectedVendorUser.length>2"><label htmlFor="">...</label></span>
              </div>
            </td>

            <td style="width:200px">
              <div class="row" id="currDD1" style="width:200px">
                <div class="col-md-6 " style="padding: 0px;">
                  <input [disabled]="!enableVendorInputMode || requestData.status=='Bid Finalized'" class="form-control"
                    style="width: 99%;height:38px" pInputText type="text" currencyMask
                    [options]="{ prefix: currencySymbol, thousands: ','}" [(ngModel)]="rowData.bidAmount">

                </div>
                <div class="col-md-6" style="padding: 0px;">
                  <p-dropdown name="currency" appendTo="body" [options]="currencyOpts"
                    [(ngModel)]="rowData.currencyType" [baseZIndex]="100000"
                    [disabled]="  !enableVendorInputMode || requestData.status=='Bid Finalized'" placeholder="Currency"
                    (onChange)="changeCurrency(rowData.currencyType)"></p-dropdown>
                  <div>
                    <span *ngIf="!rowData.validCurrency" class="error">{{'VAL_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </td>
            <td style="width:200px" *ngIf="naMexicoCountry && requestData.status!=='BU Submitted'">
              <div class="row" id="currDD1" style="width:200px">
                <div class="col-md-6 " style="padding: 0px;">
                  <input [disabled]="!enableVendorInputMode || requestData.status=='Bid Finalized'" class="form-control"
                    style="width: 99%;height:38px" pInputText type="text" currencyMask
                    [options]="{ prefix: currencySymbol, thousands: ','}" [(ngModel)]="rowData.cleaningCost1">

                </div>
                <div class="col-md-6" style="padding: 0px;">
                  <p-dropdown name="currency" appendTo="body" [options]="currencyOpts"
                    [(ngModel)]="rowData.cleaningCost1currencyType" [baseZIndex]="100000"
                    [disabled]="  !enableVendorInputMode || requestData.status=='Bid Finalized'" placeholder="Currency"
                    (onChange)="changeCurrency(rowData.cleaningCost1currencyType)"></p-dropdown>
                  <div>
                    <span *ngIf="!rowData.validCurrency" class="error">{{'VAL_REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </td>
            <td style="width:200px" *ngIf="selectedRegion =='EMEA' && requestData.status!=='BU Submitted'">
              <div pTooltip="{{rowData.payLoadinTons1}}">
                <input [disabled]="!enableVendorInputMode" style="height:38px;" class="form-control" pInputText
                  type="number" min="0" [(ngModel)]="rowData.payLoadinTons1" (keydown)="onlyNumberKey($event)">
              </div>
            </td>
            <td style="width:200px" *ngIf="selectedRegion =='EMEA' && requestData.status!=='BU Submitted'">
              <div pTooltip="{{rowData.leadTimeinDays1}}">
                <input [disabled]="!enableVendorInputMode" style="height:38px;" class="form-control" pInputText
                  type="number" min="0" [(ngModel)]="rowData.leadTimeinDays1" (keydown)="onlyNumberKey($event)">
              </div>
            </td>
            <td style="width:80px" *ngIf="requestData.status=='Bid Finalized'">
              <div class="custom-control custom-radio custom-control-inline" style="z-index: 0 !important;">
                <input type="radio" id="isSelected{{ri}}" name="_isSelected_{{ri}}" style="z-index: 0 !important;"
                  [disabled]="true" class="custom-control-input" value="true" [checked]="rowData.isSelected"
                  (change)="onSelectionChange(rowData,'isSelected')">
                <label class="custom-control-label" for="isSelected{{ri}}">{{'YES' | translate}}</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline" style="z-index: 0 !important;">
                <input type="radio" id="isSelected_{{ri}}" name="_isSelected_{{ri}}" style="z-index: 0 !important;"
                  [disabled]="true" class="custom-control-input" value="false" [checked]="!rowData.isSelected"
                  (change)="onSelectionChange(rowData,'isSelected')">
                <label class="custom-control-label" for="isSelected_{{ri}}">{{'NO' | translate}}</label>
              </div>
              <div>
                <span></span>
              </div>
            </td>

            <td style="width:140px" *ngIf="requestData.status=='Bid Finalized'">
              <p-dropdown [disabled]="true" appendTo="body" [readonly]="disableEditMode" name="rank"
                styleClass="cust-rank-dropdown" placeholder="Assign Rank" [options]="ranks" [baseZIndex]="100000"
                [(ngModel)]="rowData.rankAssigned" [style]="{'width':'100%'}"></p-dropdown>
            </td>

            <td style="width:200px">
              <div class="row" id="currDD2" style="width:200px">
                <div class="col-md-6 " style="padding: 0px;">
                  <input [disabled]="!enableVendorInputMode || requestData.status=='Bid Finalized'" class="form-control"
                    style="width: 99%;height:38px" pInputText type="text" currencyMask
                    [options]="{ prefix: currencySymbol, thousands: ','}" [(ngModel)]="rowData.bidAmount2">

                </div>
                <div class="col-md-6" style="padding: 0px;">
                  <p-dropdown name="currency2" appendTo="body" [options]="currencyOpts"
                    [(ngModel)]="rowData.currencyType2" [baseZIndex]="100000"
                    [disabled]="!enableVendorInputMode || requestData.status=='Bid Finalized'" placeholder="Currency"
                    (onChange)="changeCurrency(rowData.currencyType2)"></p-dropdown>

                  <div>
                    <span *ngIf="!rowData.validCurrency" class="error">{{'REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </td>
            <td style="width:200px" *ngIf="naMexicoCountry && requestData.status!=='BU Submitted'">
              <div class="row" id="currDD2" style="width:200px">
                <div class="col-md-6 " style="padding: 0px;">
                  <input [disabled]="!enableVendorInputMode || requestData.status=='Bid Finalized'" class="form-control"
                    style="width: 99%;height:38px" pInputText type="text" currencyMask
                    [options]="{ prefix: currencySymbol, thousands: ','}" [(ngModel)]="rowData.cleaningCost2">

                </div>
                <div class="col-md-6" style="padding: 0px;">
                  <p-dropdown name="currency2" appendTo="body" [options]="currencyOpts"
                    [(ngModel)]="rowData.cleaningCost2currencyType" [baseZIndex]="100000"
                    [disabled]="  !enableVendorInputMode || requestData.status=='Bid Finalized'"
                    (onChange)="changeCurrency(rowData.cleaningCost2currencyType)"></p-dropdown>
                  <div>
                    <span *ngIf="!rowData.validCurrency" class="error">{{'REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </td>

            <td style="width:200px" *ngIf="selectedRegion =='EMEA' && requestData.status!=='BU Submitted'">
              <div pTooltip="{{rowData.payLoadinTons2}}">
                <input [disabled]="!enableVendorInputMode" style="height:38px;" class="form-control" pInputText
                  type="number" min="0" [(ngModel)]="rowData.payLoadinTons2" (keydown)="onlyNumberKey($event)">
              </div>
            </td>
            <td style="width:200px" *ngIf="selectedRegion =='EMEA' && requestData.status!=='BU Submitted'">
              <div pTooltip="{{rowData.leadTimeinDays2}}">
                <input [disabled]="!enableVendorInputMode" style="height:38px;" class="form-control" pInputText
                  type="number" min="0" [(ngModel)]="rowData.leadTimeinDays2" (keydown)="onlyNumberKey($event)">
              </div>
            </td>
            <td style="width:80px" *ngIf="requestData.status=='Bid Finalized'">
              <div class="custom-control custom-radio custom-control-inline" style="z-index: 0 !important;">
                <input type="radio" id="isSelected2{{ri}}" name="_isSelected2_{{ri}}" style="z-index: 0 !important;"
                  [disabled]="disableEditMode" class="custom-control-input" value="true" [checked]="rowData.isSelected2"
                  (change)="onSelectionChange(rowData,'isSelected2')">
                <label class="custom-control-label" for="isSelected2{{ri}}">{{'YES' | translate}}</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline" style="z-index: 0 !important;">
                <input type="radio" id="isSelected2_{{ri}}" name="_isSelected2_{{ri}}" style="z-index: 0 !important;"
                  [disabled]="disableEditMode" class="custom-control-input" value="false"
                  [checked]="!rowData.isSelected2" (change)="onSelectionChange(rowData,'isSelected2')">
                <label class="custom-control-label" for="isSelected2_{{ri}}">{{'NO' | translate}}</label>
              </div>
              <div>
                <span></span>
              </div>
            </td>

            <td style="width:140px" *ngIf="requestData.status=='Bid Finalized'">
              <p-dropdown [disabled]="true" appendTo="body" [readonly]="disableEditMode" name="rank"
                styleClass="cust-rank-dropdown" placeholder="Assign Rank" [options]="ranks" [baseZIndex]="100000"
                [(ngModel)]="rowData.rankAssigned2" [style]="{'width':'100%'}"></p-dropdown>
            </td>


            <td style="width:160px">
              <div style="width:160px">
                {{rowData.dueDate | date:'MM-dd-yyyy'}}
              </div>
            </td>

            <td style="width:300px">
              <div pTooltip="{{rowData.comments}}">
                <input [disabled]="!enableVendorInputMode" style="height:38px;" class="form-control" pInputText
                  type="text" [(ngModel)]="rowData.comments">
              </div>
            </td>

            <td style="width:200px">
              <div>
                <div class="row">
                  <div class="col-md-8 align-content-center" style="overflow: hidden;word-wrap: break-word;">
                    <span
                      *ngIf="rowData.vendorPdfFileName != null && rowData.vendorPdfFileName != undefined && rowData.vendorPdfFileName != ''">
                      <a style="padding:10px;cursor: pointer;color: blue;"
                        (click)="downloadVendorPdfAttachment(rowData)">
                        {{rowData.vendorPdfFileName}} </a>
                    </span>

                    <span *ngIf="rowData.vendorPdfFileName == null || rowData.vendorPdfFileName == undefined 
                        || rowData.vendorPdfFileName == ''">
                      {{'VENDOR_GRID.NO_ATTACHMENT' | translate}}
                    </span>
                  </div>
                  <div class="col-md-4" style="align-content: center">


                    <p-fileUpload *ngIf="vendorPdfButtonVisible" #pVendorPdfFileUpload mode="basic" name="myfile[]"
                      showUploadButton="true" customUpload="true" accept=".pdf" [auto]="true" chooseLabel=""
                      (onClear)="OnRemovePdfAttachment(rowData)"
                      (uploadHandler)="OnVendorPdfFileUpload($event,rowData)">
                    </p-fileUpload>
                    <span
                      *ngIf="rowData.vendorPdfFileName != null && rowData.vendorPdfFileName != undefined && rowData.vendorPdfFileName != ''"
                      (click)="OnRemovePdfAttachment(rowData)" style="cursor:pointer;color: Red;"
                      class="pi pi-close">{{'VENDOR_GRID.REMOVE' | translate}}</span>
                  </div>
                </div>
              </div>

            </td>

            <td style="width:100px">
              <a class="viewhyperlink" (click)="showHistory(rowData)">{{'VENDOR_GRID.VIEW' | translate}}</a>
            </td>

          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-rowData let-ri="rowIndex" *ngIf="!vendorUser">
          <tr>
            <td style="width:30px" *ngIf="requestData.status!=='Bid Finalized'">
              <p-tableCheckbox [value]="rowData" [disabled]="!rowData.vendorId"></p-tableCheckbox>
            </td>

            <td style="max-width:200px">
              <div *ngIf="rowData.disable">
                {{rowData.vendorName}}
              </div>
              <div *ngIf="!rowData.disable">
                <p-dropdown name="vendor" appendTo="body" styleClass="cust-dropdown-vendor" [options]="vendorOpts"
                  [filter]="true" filterBy="label" [(ngModel)]="rowData.vendorId" [disabled]="disableEditMode"
                  (onChange)="vendorSelected(rowData)" [baseZIndex]="100000"
                  placeholder="{{'VENDOR_GRID.SELECT_VENDOR' | translate}}"></p-dropdown>
              </div>
              <div>
                <span *ngIf="!rowData.validVendor" class="error">{{'VAL_VENDOR_REQUIRED' | translate}}</span>
              </div>
            </td>

            <td style="width:200px">
              <div *ngIf="requestData.status=='Bid Finalized'" pTooltip='{{rowData.selectedVendorNames}}'>
                <div *ngFor="let user of rowData.selectedVendorUser;index as i">
                  <span *ngIf="i<2">
                    <label htmlFor="user.Name">{{user.Name}}</label>
                    <span *ngIf="rowData.selectedVendorUser.length!==i+1">,</span>
                  </span>
                </div>
                <span *ngIf="rowData.selectedVendorUser.length>2"><label htmlFor="">...</label></span>
              </div>
              <div *ngIf="requestData.status!=='Bid Finalized'" style="z-index:100000">
                <p-multiSelect name="vendorUsers" appendTo="body" [baseZIndex]="100000" styleClass="cust-dropdown"
                  [options]="rowData.vendorUser" optionLabel="Name" [(ngModel)]="rowData.selectedVendorUser"
                  maxSelectedLabels="1" selectedItemsLabel="{0} Users selected" [disabled]="disableEditMode"
                  defaultLabel="{{'VENDOR_GRID.CHOOSE' | translate}}"></p-multiSelect>
              </div>

            </td>

            <td style="width:50px;">
              <img *ngIf="!rowData.isNotified" class="accordion-img-icon pointer-cursor"
                title="{{'VENDOR_GRID.NO_EMAIL_SENT' | translate}}" alt="notifyStatus"
                src="../../../assets/images/icons/email-icon-red.png" height="30px" width="30px">
              <img *ngIf="rowData.isNotified" class="accordion-img-icon pointer-cursor"
                title="{{'VENDOR_GRID.LAST_NOT_ON' | translate}} {{rowData.notifiedDate | date}}" alt="notifyStatus"
                src="../../../assets/images/icons/email-icon-green.png" height="30px" width="30px">
            </td>

            <td style="width:200px" *ngIf="requestData.status!=='BU Submitted'">
              <div class="row" id="currDD1" style="width:200px">
                <div class="col-md-6 " style="padding: 0px;">
                  <input [disabled]="requestData.status=='Bid Finalized'" [readonly]="disableEditMode"  class="form-control"
                    style="width: 99%;height:38px" pInputText type="text" currencyMask
                    [options]="{ prefix: currencySymbol, thousands: ','}" [(ngModel)]="rowData.bidAmount">

                </div>
                <div class="col-md-6" style="padding: 0px;">
                  <p-dropdown name="currency" appendTo="body" [options]="currencyOpts"
                    [(ngModel)]="rowData.currencyType" [baseZIndex]="100000"
                    [disabled]="requestData.status=='Bid Finalized'" [readonly]="disableEditMode"  placeholder="Currency"
                    (onChange)="changeCurrency(rowData.currencyType)"></p-dropdown>
                  <div>
                    <span *ngIf="!rowData.validCurrency" class="error">{{'REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </td>
            <td style="width:200px" *ngIf="naMexicoCountry && requestData.status!=='BU Submitted'">
              <div class="row" id="currDD1" style="width:200px">
                <div class="col-md-6 " style="padding: 0px;">
                  <input [disabled]="requestData.status=='Bid Finalized'" class="form-control"
                    style="width: 99%;height:38px" pInputText type="text" currencyMask
                    [options]="{ prefix: currencySymbol, thousands: ','}" [readonly]="disableEditMode"  [(ngModel)]="rowData.cleaningCost1">

                </div>
                <div class="col-md-6" style="padding: 0px;">
                  <p-dropdown name="currency" appendTo="body" [options]="currencyOpts"
                    [(ngModel)]="rowData.cleaningCost1currencyType" [baseZIndex]="100000"
                    [disabled]="requestData.status=='Bid Finalized'" placeholder="Currency" [readonly]="disableEditMode" 
                    (onChange)="changeCurrency(rowData.CleaningCost1currencyType)"></p-dropdown>
                  <div>
                    <span *ngIf="!rowData.validCurrency" class="error">{{'REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </td>
            <td style="width:200px" *ngIf="selectedRegion =='EMEA' && requestData.status!=='BU Submitted'">
              <div pTooltip="{{rowData.payLoadinTons1}}">
                <input style="height:38px;" class="form-control" pInputText
                  type="number"  [(ngModel)]="rowData.payLoadinTons1"  min="0" (keydown)="onlyNumberKey($event)">
              </div>
            </td>
            <td style="width:200px" *ngIf="requestData.status!=='BU Submitted' && selectedRegion =='EMEA'">
              <div pTooltip="{{rowData.leadTimeinDays1}}">
                <input  style="height:38px;" class="form-control" pInputText
                  type="number" min="0" [(ngModel)]="rowData.leadTimeinDays1" (keydown)="onlyNumberKey($event)">
              </div>
            </td>

            <td style="width:80px" *ngIf="requestData.status!=='BU Submitted'">
              <div class="custom-control custom-radio custom-control-inline" style="z-index: 0 !important;">
                <input type="radio" id="isSelected{{ri}}" name="_isSelected_{{ri}}" style="z-index: 0 !important;"
                  [disabled]="!enableAwardingSubmit || requestData.status=='Bid Finalized'" class="custom-control-input"
                  value="true" [checked]="rowData.isSelected" (change)="onSelectionChange(rowData,'isSelected')">
                <label class="custom-control-label" for="isSelected{{ri}}">{{'YES' | translate}}</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline" style="z-index: 0 !important;">
                <input type="radio" id="isSelected_{{ri}}" name="_isSelected_{{ri}}" style="z-index: 0 !important;"
                  [disabled]="!enableAwardingSubmit || requestData.status=='Bid Finalized'" class="custom-control-input"
                  value="false" [checked]="!rowData.isSelected" (change)="onSelectionChange(rowData,'isSelected')">
                <label class="custom-control-label" for="isSelected_{{ri}}">{{'NO' | translate}}</label>
              </div>
              <div>
                <span></span>
              </div>
            </td>

            <td style="width:140px" *ngIf="requestData.status!=='BU Submitted'">
              <p-dropdown [disabled]="!rowData.isSelected || requestData.status=='Bid Finalized'" appendTo="body"
                [readonly]="disableEditMode" name="rank" styleClass="cust-rank-dropdown" placeholder="Assign Rank"
                [options]="ranks" [baseZIndex]="100000" [(ngModel)]="rowData.rankAssigned"
                [style]="{'width':'100%'}"></p-dropdown>
              <div>
                <span *ngIf="!rowData.validRank" class="error">{{'VAL_RANK_REQUIRED' | translate}}</span>
              </div>
            </td>

            <td style="width:200px" *ngIf="requestData.status!=='BU Submitted'">
              <div class="row" id="currDD2" style="width:200px">
                <div class="col-md-6 " style="padding: 0px;">
                  <input [disabled]="requestData.status=='Bid Finalized'" class="form-control"
                    style="width: 99%;height:38px" pInputText type="text" currencyMask
                    [options]="{ prefix: currencySymbol, thousands: ','}" [readonly]="disableEditMode"  [(ngModel)]="rowData.bidAmount2">

                </div>
                <div class="col-md-6" style="padding: 0px;">
                  <p-dropdown name="currency2" appendTo="body" [options]="currencyOpts"
                    [(ngModel)]="rowData.currencyType2" [disabled]="requestData.status=='Bid Finalized'"
                    placeholder="Currency" [readonly]="disableEditMode"  [baseZIndex]="100000"
                    (onChange)="changeCurrency(rowData.currencyType2)"></p-dropdown>
                  <div>
                    <span *ngIf="!rowData.validCurrency" class="error">{{'REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </td>
            <td style="width:200px" *ngIf="requestData.status!=='BU Submitted' && selectedRegion =='EMEA'">
              <div pTooltip="{{rowData.payLoadinTons2}}">
                <input  style="height:38px;" class="form-control" pInputText
                  type="number" min="0" [(ngModel)]="rowData.payLoadinTons2" (keydown)="onlyNumberKey($event)">
              </div>
            </td>
            <td style="width:300px" *ngIf="requestData.status!=='BU Submitted' && selectedRegion =='EMEA'">
              <div pTooltip="{{rowData.leadTimeinDays2}}">
                <input style="height:38px;" class="form-control" pInputText
                  type="number" min="0" [(ngModel)]="rowData.leadTimeinDays2" (keydown)="onlyNumberKey($event)">
              </div>
            </td>
            <td style="width:200px" *ngIf="requestData.naCountry=='Mexico' && requestData.status!=='BU Submitted'">
              <div class="row" id="currDD2" style="width:200px">
                <div class="col-md-6 " style="padding: 0px;">
                  <input [disabled]="requestData.status=='Bid Finalized'" class="form-control"
                    style="width: 99%;height:38px" pInputText type="text" currencyMask
                    [options]="{ prefix: currencySymbol, thousands: ','}" [readonly]="disableEditMode"  [(ngModel)]="rowData.cleaningCost2">

                </div>
                <div class="col-md-6" style="padding: 0px;">
                  <p-dropdown name="currency2" appendTo="body" [options]="currencyOpts"
                    [(ngModel)]="rowData.cleaningCost2currencyType" [disabled]="requestData.status=='Bid Finalized'"
                    placeholder="Currency" [readonly]="disableEditMode"  [baseZIndex]="100000"
                    (onChange)="changeCurrency(rowData.cleaningCost2currencyType)"></p-dropdown>
                  <div>
                    <span *ngIf="!rowData.validCurrency" class="error">{{'REQUIRED' | translate}}</span>
                  </div>
                </div>
              </div>
            </td>

            <td style="width:80px" *ngIf="requestData.status!=='BU Submitted'">
              <div class="custom-control custom-radio custom-control-inline" style="z-index: 0 !important;">
                <input type="radio" id="isSelected2{{ri}}" name="_isSelected2_{{ri}}" style="z-index: 0 !important;"
                  [disabled]="!enableAwardingSubmit || requestData.status=='Bid Finalized'" class="custom-control-input"
                  value="true" [checked]="rowData.isSelected2" (change)="onSelectionChange(rowData,'isSelected2')">
                <label class="custom-control-label" for="isSelected2{{ri}}">{{'YES' | translate}}</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline" style="z-index: 0 !important;">
                <input type="radio" id="isSelected2_{{ri}}" name="_isSelected2_{{ri}}" style="z-index: 0 !important;"
                  [disabled]="!enableAwardingSubmit || requestData.status=='Bid Finalized'" class="custom-control-input"
                  value="false" [checked]="!rowData.isSelected2" (change)="onSelectionChange(rowData,'isSelected2')">
                <label class="custom-control-label" for="isSelected2_{{ri}}">{{'NO' | translate}}</label>
              </div>
              <div>
                <span></span>
              </div>
            </td>

            <td style="width:140px" *ngIf="requestData.status!=='BU Submitted'">
              <p-dropdown [disabled]="!rowData.isSelected2 || requestData.status=='Bid Finalized'" appendTo="body"
                [readonly]="disableEditMode" name="rank" styleClass="cust-rank-dropdown" placeholder="Assign Rank"
                [options]="ranks" [baseZIndex]="100000" [(ngModel)]="rowData.rankAssigned2"
                [style]="{'width':'100%'}"></p-dropdown>
              <div>
                <span *ngIf="!rowData.validRank2" class="error">{{'VAL_RANK_REQUIRED' | translate}}</span>
              </div>
            </td>


            <td style="width:160px; background:none">
              <p-calendar appendTo="body" [disabled]="requestData.status=='Bid Finalized' " name="startDate"
                [showIcon]="true" inputid="icon" dateFormat="yy-mm-dd" [locale]="lang" style="width: fit-content; 
                  padding: 0px !important;
                  height: 2.6rem;" styleClass="min-w-custom" [(ngModel)]="rowData.dueDate"
                [disabledDays]="[0,6]" [minDate]="minDateValue" (onSelect)="onDateChange(rowData)">
              </p-calendar>

              <div>
                <span *ngIf="!rowData.validDueDate" class="error">{{'VAL_PROVIDE_WEEKDAY' | translate}}</span>
              </div>
            </td>

            <td style="width:300px" *ngIf="requestData.status!=='BU Submitted'">
              <div pTooltip="{{rowData.comments}}">
                <input [disabled]="requestData.status=='Bid Finalized'" style="height: 38px;" class="form-control"
                  pInputText type="text" [(ngModel)]="rowData.comments" [readonly]="disableEditMode" >
              </div>
            </td>

            <td style="width:200px">
              <div>
                <a *ngIf="rowData.vendorPdfFileName != null && rowData.vendorPdfFileName != undefined && rowData.vendorPdfFileName != ''"
                  style="cursor: pointer;color: blue;" (click)="downloadVendorPdfAttachment(rowData)">
                  {{rowData.vendorPdfFileName}}
                </a>
                <span *ngIf="rowData.vendorPdfFileName == null || rowData.vendorPdfFileName == undefined 
                    || rowData.vendorPdfFileName == ''">
                  {{'VENDOR_GRID.NO_ATTACHMENT' | translate}}
                </span>
              </div>

            </td>


            <td style="width:100px">
              <a class="viewhyperlink" (click)="showHistory(rowData)">{{'VENDOR_GRID.VIEW' | translate}}</a>
            </td>

            <td *ngIf="requestData.status!=='Bid Finalized' && !disableEditMode"
              style="text-align:center;width: 150px;position: sticky;">
              <button pButton type="button" icon="pi pi-plus" class="ui-button-info cust-icons" style="color: #005b9f;"
                (click)="addVendor(rowData)"></button>
              <button pButton type="button" icon="pi pi-minus" class="ui-button-danger cust-icons me-4"
                style="margin-left: .5em; color: red;" (click)="openDeleteVendorPopup(rowData)"></button>
            </td>

          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td>
              <app-cust-spinner></app-cust-spinner>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-accordionTab>
</p-accordion>

<div class="row" style="margin-top: 20px;"
  *ngIf="enableAwardingSubmit && !vendorUser && requestData.status!=='BU Submitted'">
  <div class="form-group col-md-12">
    <div class="row">
      <div class="col-md-3">
        <label for="comments" class="cust-label col-form-label">{{'VENDOR_GRID.REASON_FOR_SELECTION' |
          translate}}</label>
      </div>
      <div class="col-md-9">
        <textarea [disabled]="requestData.status=='Bid Finalized' || disableEditMode" rows="4" name="comments"
          [(ngModel)]="reasonForSelection" class="form-control" style="width: 100%;"></textarea>
        <span *ngIf="!validReason" class="error">{{'VAL_PROVIDE_VALID_VALUE' | translate}}</span>
      </div>
    </div>
  </div>
</div>

<div class="row" style="margin-top: 20px;"
  *ngIf="enableAwardingSubmit && !vendorUser && requestData.status!=='BU Submitted'">
  <div class="form-group col-md-12 attach">
    <div class="row">
      <div class="col-md-3">
        <label for="additionalAttachment" class="cust-label col-form-label">{{'VENDOR_GRID.RATE_REQ_CALC' |
          translate}}</label>
      </div>
      <div class="col-md-9">
        <div *ngIf="requestData.status!=='Bid Finalized'" style="margin-top:6px">
          <span *ngIf="isRateFileUpload">{{rateReqCalFileName}}
            <span *ngIf="isRateFileUpload" (click)="pRateFileUpload.clear()"
              style="margin-left: 10px; cursor:pointer;color: blue;" class="pi pi-close">{{'VENDOR_GRID.REMOVE' |
              translate}}</span>
          </span>
          <p-fileUpload #pRateFileUpload mode="basic" name="myfile[]" showUploadButton="false" customUpload="true"
            accept=".xls, .xlsx" [auto]="true" chooseLabel="{{'CLICK_BROWSE' | translate}}"
            (onClear)="OnRemoveRateAttachment()" (uploadHandler)="OnRateFileUpload($event)">
          </p-fileUpload>
        </div>
        <div *ngIf="requestData.status=='Bid Finalized'" style="margin-top:6px">
          <a *ngIf="rateReqCalFileName != null && rateReqCalFileName != undefined && rateReqCalFileName != ''"
            style="cursor: pointer;color: blue;" (click)="downLoadRateReqCal()">
            {{rateReqCalFileName}}
          </a>
        </div>

      </div>
    </div>
  </div>
</div>


<div
  *ngIf="enableAwardingSubmit && !vendorUser && requestData.status!=='Bid Finalized' && requestData.status!=='BU Submitted' && !disableEditMode"
  class="row" style="margin-top: 20px;">
  <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
    <p style="text-align: right">
      <input type="button" class="btn btn-secondary cust-btns" (click)="canelSubmit()" value="{{'CANCEL' | translate}}">
    </p>
  </div>
  <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
    <p style="text-align: left">
      <input type="button" class="btn btn-primary cust-btns" style="background-color: #004A96 !important;"
        (click)="openCofirmPopup()" value="{{'SUBMIT' | translate}}">
    </p>
  </div>
</div>

<div *ngIf="vendorUser && requestData.status!=='Bid Finalized' && enableVendorInputMode" class="row"
  style="margin-top: 20px;">
  <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
    <p style="text-align: right">
      <input type="button" class="btn btn-secondary cust-btns" (click)="cancelvendorSubmit()"
        value="{{'CANCEL' | translate}}">
    </p>
  </div>
  <div class="form-group col-6 col-md-6" style="margin-bottom: 10px;">
    <p style="text-align: left">
      <input type="button" class="btn btn-primary cust-btns" style="background-color: #004A96 !important;"
        (click)="submitBidByVendor()" value="{{'SUBMIT' | translate}}">
    </p>
  </div>
</div>
<p-confirmDialog #cd [style]="{width: '50vw'}">
  <p-footer>
    <button type="button" pButton icon="pi pi-times" label='{{"NO" | translate}}' (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label='{{"Yes" | translate}}' (click)="cd.accept()"></button>
  </p-footer>
</p-confirmDialog>
<p-dialog header="{{vendorHeader}}- {{'VENDOR_GRID.BID_HISTORY' | translate}}" [(visible)]="displayHistory"
  [style]="{width: '80vw'}">
  <p-table [value]="vendorsHistory" [scrollable]="true" [style]="{width:'100%'}" dataKey="vendorId" [autoLayout]="true">
    <ng-template pTemplate="header">
      <tr>
        <th id="hist_submit_date" style="width: 130px;">{{'VENDOR_GRID.SUBMIT_DATE' | translate}}</th>
        <th id="hist_rate_1" style="width: 120px;">{{rate1_Label | translate}}</th>
        <th id="payload1" style="width: 120px;" *ngIf="selectedRegion =='EMEA' && requestData.status!=='BU Submitted'">
          Pay Load (Tons)</th>
        <th id="loadtime1" style="width: 120px;" *ngIf="selectedRegion =='EMEA' && requestData.status!=='BU Submitted'">
          Lead Time (Days)</th>
        <th id="hist_cc1" style="width: 130px;" *ngIf="naMexicoCountry">{{'VENDOR_GRID.CLENING_COST1' | translate}}</th>
        <th id="hist_rate2" style="width: 120px;">{{rate2_Label | translate}}</th>
        <th id="payload2" style="width: 120px;" *ngIf="selectedRegion =='EMEA' && requestData.status!=='BU Submitted'">
          Pay Load (Tons)</th>
        <th id="loadtime2" style="width: 120px;" *ngIf="selectedRegion =='EMEA' && requestData.status!=='BU Submitted'">
          Lead Time (Days)</th>
        <th id="hist_cc2" style="width: 130px;" *ngIf="naMexicoCountry">{{'VENDOR_GRID.CLENING_COST2' | translate}}</th>
        <th id="hist_comments" style="width: 250px;">{{'COMMENTS' | translate}}</th>
        <th id="hist_submittedBy" style="width: 150px;">{{'VENDOR_GRID.SUBMITTED_BY' | translate}}</th>

      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-rowHisData let-ri="rowIndex">
      <tr>
        <td style="width: 130px;">
          <div>
            {{rowHisData.bidSubmittedDate | date}}
          </div>
        </td>
        <td style="width: 120px;">
          {{rowHisData.bidAmount}} {{rowHisData.currencyType}}
        </td>
        <td style="width: 120px;" *ngIf="selectedRegion =='EMEA' && requestData.status!=='BU Submitted'">
          {{rowHisData.payLoadinTons1}}
        </td>
        <td style="width: 120px;" *ngIf="selectedRegion =='EMEA' && requestData.status!=='BU Submitted'">
          {{rowHisData.leadTimeinDays1}}
        </td>
        <td style="width: 130px;" *ngIf="naMexicoCountry">
          {{rowHisData.cleaningCost1}} {{rowHisData.cleaningCost1currencyType}}
        </td>
        <td style="width: 120px;">
          {{rowHisData.bidAmount2}} {{rowHisData.currencyType2}}
        </td>
        <td style="width: 120px;" *ngIf="selectedRegion =='EMEA' && requestData.status!=='BU Submitted'">
          {{rowHisData.payLoadinTons2}}
        </td>
        <td style="width: 120px;" *ngIf="selectedRegion =='EMEA' && requestData.status!=='BU Submitted'">
          {{rowHisData.leadTimeinDays2}}
        </td>
        <td style="width: 130px;" *ngIf="naMexicoCountry">
          {{rowHisData.cleaningCost2}} {{rowHisData.cleaningCost2currencyType}}
        </td>
        <td style="width: 250px;">
          {{rowHisData.comments}}
        </td>
        <td style="width: 150px;">
          {{rowHisData.bidSubmittedBy}}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td>
          {{'VENDOR_GRID.NO_HISTORY_EXISTS' | translate}}
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>