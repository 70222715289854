import { EventEmitter, Input, Output, Directive } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { ConfirmationService } from "primeng/api";
import { RequestFormData } from "src/app/requests/data/formdata/request-common.data";
import { RequestFormModel } from "src/app/requests/request-form.model";
import { DropDownMaster } from "src/app/shared/dropdown-master.data";
import { CurrencyDropDownMaster, VendorDropDownMaster, VendorUserDropdownMaster } from "src/app/shared/vendor-dropdown-master.data";
import { LoggecInUserInfo } from "src/app/site-permissions/data/loggedin-user-info";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { VendorResponseFormData } from "../data/vendor-response-form.data";
import { VendorResponseData } from "../data/vendor-response.data";
import { VendorResponseMasterModel } from "../vendor-response-master.model";
import { VendorResponseModel } from "../vendor-response.model";
import { formatDate } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";


@Directive()
export abstract class VendorUpdateCommonComponent {
    userAccessPermission!: LoggecInUserInfo;

    vendorOpts: VendorDropDownMaster[] = [];
    vendorsList: VendorDropDownMaster[] = [];
    currencyOpts: CurrencyDropDownMaster[] = [];
    ranks: DropDownMaster[] = [];

    requestData!: RequestFormData;
    vendorObj!: VendorResponseData;
    vendorsData: VendorResponseData[] = []
    finalVendors: VendorResponseData[] = []

    selectedVendors:VendorResponseData[]=[]
    currencySymbol: string = "";
    reasonForSelection: string = "";

    isRateFileUpload:boolean=false;
    rateReqCalData:any;
    rateReqCalFileName:string="";
    rateReqCalFileExtn:string="";
    fileDownloaded:boolean=false;

    vendorPdfButtonVisible:boolean=true;

    disableEditMode!: boolean;
    clearVendors: boolean = false;
    validReason: boolean = true;
    // minDateValue:any=formatDate(new Date(),'yyyy-MM-dd','en-Us');
    minDateValue = new Date();
    vendorUser:boolean=sessionStorage.getItem('isVendorUser')=="true";
    currentRegion:any=sessionStorage['selectedRegion'];
    rate1_Label:string='VENDOR_GRID.TRUCK_RATE';
    rate2_Label:string='VENDOR_GRID.INTERMODAL_RATE';
    VendorUsersList:VendorUserDropdownMaster[]=[];
    selectedVendorUsers:VendorUserDropdownMaster[]=[]

    enableAwardingSubmit:boolean=true;
    enableVendorInputMode:boolean=false;
    vendorsHistory:any[]=[];
    vendorHeader:any;
    displayHistory:boolean=false;

    isMexicoAmountDisplay:boolean=false;
    naMexicoCountry:boolean=false;

    @Input() requestId!: number;
    @Input() country!: string;
    @Input() transportType!: number;
    @Input() selectedRegion :any;
    @Input() statusId!: number;
    @Output() refresh=new EventEmitter();

      abstract onRetrieveRequestCompleted():any;
      abstract onRetrieveVendorResponseCompleted():any;
      abstract onfilterVendorName(rowData: any):any ;
      abstract bindVendorResponseResult(element: any) :any;
      abstract CreateNewVendorResponseRow():any;
      abstract isVendorsDueDatePassed():any;
      abstract rearrangeRanksonRemove(rowData:any):any

      
      constructor(private translater:TranslateService,private spinners:NgxSpinnerService){
        if(this.currentRegion=='EMEA'){
          this.rate1_Label='VENDOR_GRID.RATE_1';
          this.rate2_Label='VENDOR_GRID.RATE_2'
        }
      }

    changeCurrency(currencyType:any) {
        let curr = this.currencyOpts.filter(c => c.value == currencyType)[0];
        if (curr.label == "USD") {
            this.currencySymbol = "";
        }
    }



    onAddVendor(rowData: any, toast: ToastrService,vendorObj:VendorResponseFormData) {
      let validVendor:boolean=true;
      this.vendorsData.forEach(ven=>{
        this.vendorOpts = this.vendorOpts.filter((v:any) => v.value != ven.vendorId);
        if(ven.vendorId==0){
          (ven as VendorResponseFormData).validVendor=false;
          validVendor=false;
        }
      })
        if (rowData.vendorId != null && rowData.vendorId != undefined && rowData.vendorId != "" && validVendor) {
            let dVobj = this.vendorsData.filter(f => f.vendorId == rowData.vendorId);
            if (dVobj.length == 0) {
                this.vendorsData.push(rowData);
            }
            this.onfilterVendorName(rowData);           
            let rObj = {
                value: this.ranks.length + 1,
                label: (this.ranks.length + 1).toString()
            };            
            this.ranks.push(rObj);
            this.ranks.push({value:this.ranks.length+1,label:(this.ranks.length+1).toString()});            
            vendorObj.currencyType=this.requestData.currencyId;
            this.vendorsData.push(vendorObj);
            this.isVendorsDueDatePassed();
        }
        else {
            toast.warning(this.translater.instant("VENDOR_GRID.PLEASE_PROVIDE_VENDOR"));
            if(rowData.vendorId==0){
              rowData.validVendor = false;
            }            
        }
    }
   public onlyNumberKey(evt:any) {
 
      // Only ASCII character in that range allowed
      let ASCIICode = (evt.which) ? evt.which : evt.keyCode
      if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode >= 55))
          return false;
      return true;
  }
    calculateDueDate(d:any){
      let todaysDate=new Date();
      if(todaysDate.getDay()==0 || todaysDate.getDay()==1 || todaysDate.getDay()==2){
              d=new Date(todaysDate.setDate(todaysDate.getDate()+3));
       }
      else if(todaysDate.getDay()==3 || todaysDate.getDay()==4 || todaysDate.getDay()==5){
              d=new Date(todaysDate.setDate(todaysDate.getDate()+5));
      }
      else{
              d=new Date(todaysDate.setDate(todaysDate.getDate()+4));
      }
      return d;
    }
  

  onCreateNewVendorResponseRow(vendorObj:VendorResponseFormData){
    vendorObj.currencyType=this.requestData.currencyId;
    this.vendorsData.push(vendorObj);
  }

 onDateChange(rowData:any){
  
    this.isVendorsDueDatePassed();
  }
    

onOpenDeleteVendorPopup(rowData:any,cService: ConfirmationService) {
    let rData = rowData;
    if (rData.vendorId != "") {
      cService.confirm({
        message: this.translater.instant('SURE_TO_REMOVE_VENDOR'),
        header: this.translater.instant('CONFIRMATION'),

        accept: () => {
          this.onRemoveVendor(rData);
        },
        reject: () => {

        }
      });
    } else {
      this.onRemoveVendor(rData);
    }
  }

  onRemoveVendor(rowData:any) {
    if (rowData.vendorId != "") {
      this.vendorsData = this.vendorsData.filter((v:any) => v.vendorId != rowData.vendorId);
      let uVendObj = this.vendorsList.filter((v:any) => v.value == rowData.vendorId)[0];
      let vObj = {
        value: uVendObj.value,
        label: uVendObj.label,
        email:uVendObj.email
      };
      if (this.vendorOpts.filter((v:any) => v.value == rowData.vendorId).length == 0) {
        this.vendorOpts.push(vObj);
      }
      this.vendorObj =new VendorResponseFormData()
      if (this.vendorsData.length == 0) {
        this.clearVendors = true;
        this.CreateNewVendorResponseRow();
      }
      this.rearrangeRanksonRemove(rowData);
      
    }
    else {
      this.vendorsData = this.vendorsData.filter((v:any) => v.vendorId != rowData.vendorId);
      if (this.vendorsData.length == 0) {
        this.clearVendors = true;
        this.CreateNewVendorResponseRow();
      }
    }
    this.isVendorsDueDatePassed();
  }

  
   showHistory(rowData:any){
      this.vendorsHistory=rowData.vendorBids;
      this.vendorHeader=rowData.vendorName;
      this.displayHistory=true;
   }
  
 configureRankOptions(){
      let rObj = new DropDownMaster();
      rObj.value = this.ranks.length + 1;
      rObj.label = (this.ranks.length + 1).toString();    
      this.ranks.push(rObj);
      if(this.transportType==1 || this.transportType==2 || this.transportType==3){
          this.ranks.push({value:this.ranks.length+1,label:(this.ranks.length+1).toString()})
      }        
 }
    
 retrieveVendorResponse(vendorModel: VendorResponseModel) {
    vendorModel.retrieveVendorResponse(this.requestId,this.transportType,this.vendorUser,this.userAccessPermission.userId).subscribe(response => {      
      let data: any = response; 
      data.forEach((element:any) => {
        this.configureRankOptions()
        element.vendorUser = this.VendorUsersList.filter(ven=>ven.VendorId==element.vendorId);        
        element.dueDate=element.dueDate!==null?formatDate(element.dueDate,'yyyy-MM-dd','en-Us'):null;
        let resultData = this.bindVendorResponseResult(element);
        resultData.disable = true;
        resultData.validVendor = true;
        resultData.validBidAmount = true;
        resultData.validRank = true;
        resultData.validRank2 = true;
        resultData.validComments = true;
        resultData.validCurrency = true;
        resultData.validDueDate = true;
        resultData.selectedVendorNames=resultData.selectedVendorUser.map((x:any)=>x.Name).join(', ');
        this.vendorsData.push(resultData);

        if(resultData.vendorPdfFileName!=null && resultData.vendorPdfFileName!=undefined && resultData.vendorPdfFileName!=''){
          this.vendorPdfButtonVisible=false;
        }
       
      });

      if (this.vendorsData.length == 0) {
        
        let rObj = new DropDownMaster();
  
        rObj.value = this.vendorsData.length + 1;
        rObj.label = (this.vendorsData.length + 1).toString();
        let rObj2 = new DropDownMaster();
  
        rObj2.value = this.vendorsData.length + 2;
        rObj2.label = (this.vendorsData.length + 2).toString();
  
        this.ranks.push(rObj);
        if(this.transportType==1 || this.transportType==2 || this.transportType==3){
          this.ranks.push(rObj2);
        }
        this.CreateNewVendorResponseRow();
      }
      
  
      if (this.statusId == 1 || this.statusId == 2 || this.statusId == 5 || this.statusId == 6) {
        this.disableEditMode = false;
      } else {
        this.disableEditMode = true;
      }
  
      if (!this.userAccessPermission.site_access.vendorAccess) {
        this.disableEditMode = true;
      }
  
      if (this.userAccessPermission.site_access.adminAccess && this.statusId == 7) {
        this.disableEditMode = false;
      }
      this.isVendorsDueDatePassed();
      this.onRetrieveVendorResponseCompleted();
    })
  }
  cancelvendorSubmit( ){
    console.log('');

 }
  
  

    protected validateloggedInUser(userAccessModel: LoggedInUserInfoModel, router: Router) {
        this.userAccessPermission = userAccessModel.retrieveLoggedInUserSitePermission();

        let url1 = "";
        url1 = window.location.href;
        sessionStorage.setItem('requestPath', url1);

        if (this.userAccessPermission.username === undefined || this.userAccessPermission.username === null && this.userAccessPermission.username === "" || this.userAccessPermission.username === " ") {
            router.navigate(['/login-qa']);
        }

        this.ValidateUseraccessPermission(router);

    }



    protected  retrieveRequestDetails(transportType: number, requestId: number, requestModel: RequestFormModel) {
      this.spinners.show()  
      requestModel.retrieveRequestDetails(transportType, requestId).then(response => {
            this.requestData = response;
            this.spinners.hide()
            this.onRetrieveRequestCompleted();
            this.statusId = this.requestData.requestStatusId;
            this.configurEditMode();
          });


    }

    protected retrievevendorCurrency(vendorMaster: VendorResponseMasterModel) {
        vendorMaster.retrieveVendorCurrencyMaster().subscribe(response => {
            this.currencyOpts = response;
        });
    }
    protected retrievevendorMaster(vendorMaster: VendorResponseMasterModel) {
        vendorMaster.retrieveVendorMaster(this.userAccessPermission.userId,this.transportType).subscribe(response => {
            this.vendorOpts = response;
            this.vendorsList = response;
        })        
    }
    protected retrieveVendorUserMaster(vendorMaster: VendorResponseMasterModel){
      vendorMaster.retrieveVendorUserMaster(this.transportType).subscribe(response=>{
        this.VendorUsersList=response;              
      })
    }

    private configurEditMode() {
        if (this.statusId == 1 || this.statusId == 2 || this.statusId == 5 || this.statusId == 6) {
            this.disableEditMode = false;
        } else {
            this.disableEditMode = true;
        }

        if (!this.userAccessPermission.site_access.vendorAccess) {
            this.disableEditMode = true;
        }

        if (this.userAccessPermission.site_access.adminAccess && this.statusId == 7) {
            this.disableEditMode = false;
        }
    }
    private ValidateUseraccessPermission(router: Router) {
        if (!this.userAccessPermission.site_access.appAccess && !this.userAccessPermission.site_access.lpAccess) {
            if (this.userAccessPermission.username == null || this.userAccessPermission.username == undefined
                || this.userAccessPermission.username == "" || this.userAccessPermission.username == "null"
                || this.userAccessPermission.username == "undefined"
                || this.userAccessPermission.business_unit == null || this.userAccessPermission.business_unit == undefined
                || this.userAccessPermission.business_unit == "" || this.userAccessPermission.business_unit == "null"
                || this.userAccessPermission.business_unit == "undefined") {

                router.navigate(['/login']);
            } else {
                router.navigate(['/home']);
            }
        }


    }

    OnRateFileUpload(event:any) {
      let fileData = event.files[0];
      const element = event.files[0];
      if (element.size > 3145728) {
        return false;
      } else {
        this.rateReqCalFileName = fileData.name;
        this.rateReqCalFileExtn = fileData.type;
        this.rateReqCalData = "";
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
          this.rateReqCalData = fileReader.result;
          this.rateReqCalData = this.rateReqCalData.replace(`data:${this.rateReqCalFileExtn};base64,`, '');
          this.isRateFileUpload = true;
        }
        
        fileReader.readAsDataURL(fileData);
        return true;
      }
    }

    OnVendorPdfFileUpload(event:any,rowData:any) {
      let fileData = event.files[0];
      const element = event.files[0];
      if (element.size > 3145728) {
        return false;
      } else {
        rowData.vendorPdfFileName = fileData.name;
        rowData.vendorPdfFileExtn = fileData.type;
        rowData.vendorPdfAttachment = "";
        let fileReader = new FileReader();
        fileReader.onload = (e) => {
          rowData.vendorPdfAttachment = fileReader.result;
          rowData.vendorPdfAttachment = rowData.vendorPdfAttachment.replace(`data:${rowData.vendorPdfFileExtn};base64,`, '');
        }
        
        fileReader.readAsDataURL(fileData);
        this.vendorPdfButtonVisible=false;
        return true;
      }

    }
    
    OnRemovePdfAttachment(rowData:any) {
     rowData.vendorPdfAttachment="";
     rowData.vendorPdfFileName="";
     rowData.vendorPdfFileExtn="";
     this.vendorPdfButtonVisible=true;

    }
  
    OnRemoveRateAttachment() {
      this.rateReqCalData = "";
      this.rateReqCalFileName = "";
      this.rateReqCalFileExtn = "";
      this.isRateFileUpload = false;
    }

    downloadVendorPdfAttachment(rowData:any){
      this.fileDownloaded = false;
      
      this.openpdfEdge(rowData.vendorPdfAttachment, rowData.vendorPdfFileName, rowData.vendorPdfFileExtn);
      this.fileDownloaded = true;
    }

    downLoadRateReqCal() {
      this.fileDownloaded = false;
      
      this.openpdfEdge(this.rateReqCalData, this.rateReqCalFileName, this.rateReqCalFileExtn);
      this.fileDownloaded = true;
    }

    openpdfEdge(data:any, fileName:any, dataType:any) {
      
      'use strict';

      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE workaround
        let byteCharacters = atob(data);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let blob = new Blob([byteArray], { type: dataType });
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      }
      else { // much easier if not IE
        this.downLoadCAttachment(fileName,dataType,data);
      }
  }

  downLoadCAttachment(fName:any, fType:any, fAttachment:any) {
    const downloadLink = document.createElement("a");
    const fileName = fName;

    downloadLink.href = `data:${fType};base64,${fAttachment}`;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  

}
