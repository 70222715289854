import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DropDownMaster } from "src/app/shared/dropdown-master.data";
import { LoggecInUserInfo } from "src/app/site-permissions/data/loggedin-user-info";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { UserManagementForm } from "../data/user-management-form.data";
import { UserManagementModel } from "../user-management.model";
import { UserManagementMasterModel } from "../user-master-data.model";
import { AppServiceService } from "src/app/services/app-service.service";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-admin-user-management',
  templateUrl: './admin-user-management.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [LoggedInUserInfoModel, UserManagementModel, UserManagementMasterModel]
})

export class AdminUserManagementComponent implements OnInit {

  @Input() loggedinUserinfo!: LoggecInUserInfo;

  userTable: any;
  userformData: UserManagementForm = new UserManagementForm();
  // regionsDD:any=[];
  regionsDD:DropDownMaster[]=[];
  
  user_roles_master: DropDownMaster[] = [];
  usersData: any;
  statusOpts:any;
  selectedRegion:any;
  private userSubscriptionName: Subscription; //important to create a subscription
    messageReceived: any;
    region!: string;
    selectedRegionvalue:any='';
    
  
  constructor(private userModel: UserManagementModel,
    private userMasterModel: UserManagementMasterModel,
    private spinner: NgxSpinnerService,private translate:TranslateService,
    private toast: ToastrService, private applicaionservice:AppServiceService) { 
      this.userSubscriptionName= this.applicaionservice.getUpdate().subscribe(message => { //message contains the data sent from service
     this.messageReceived = message;
     this.region=sessionStorage['selectedRegion'];
     this.ngOnInit();
     });
   }
   
   ngOnInit(): void {
    if(this.selectedRegionvalue != this.region){
      this.selectedRegionvalue=this.region;
    this.retrieveUserRoleMaster();
    this.retrieveRegionMaster();
    this.retrievUserTable();
    this.retrieveUserCollection();
    this.retrieveUserStatus();
    }
  }
  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.userSubscriptionName.unsubscribe();
}


  retrievUserTable() {
    this.userTable = this.userModel.retrieveColumnHeaders();
    
  }
  retrieveUserStatus()
  {
   this. statusOpts=this.userMasterModel.retrieveUserStatus();
  }
  retrieveUserRoleMaster() {
    this.userMasterModel.retrieveUserRoles(Number(sessionStorage['userId'])).subscribe({
      next:(userRoleMasterResult) => {
      this.user_roles_master = userRoleMasterResult;
    },
     error: error => {
        console.error(error);
      }
  });
  }

  
  retrieveRegionMaster()
    {
      this.userMasterModel.RetrieveRegionMaster(Number(sessionStorage['userId']),sessionStorage["isVendorUser"]=='true','All' ).subscribe({
        next:transportTypeResponse=>
        {
         this. regionsDD=transportTypeResponse;
        },
        error:error=>
        {
          console.error(error);
        }
    })

    }
  retrieveUserCollection() {
    this.userModel.retrieveUserCollection(Number(sessionStorage['userId'])).subscribe({
      next:userCollectionResponse => {
      this.usersData = userCollectionResponse;
    },
      error:error => {
        console.error(error);
      }
      });
  }

  onCancel() {
    this.userformData.created_date=new Date();
    this.userformData.department_id="";
    this.userformData.edit=false;
    this.userformData.editing=false;
    this.userformData.email="";
    this.userformData.first_name="";
    this.userformData.is_active=true;
    this.userformData.is_admin=true;
    this.userformData.last_name="";
    this.userformData.modified_date=new Date();
    this.userformData.role_id=0;
    this.userformData.role_name="";
    this.userformData.status="";
    this.userformData.user_id=0;
    this.userformData.user_name="";

    this.userformData.validFirstName=true;
    this.userformData.validLastName=true;
    this.userformData.validRole=true;
    this.userformData.validUserEmailId=true;
    this.userformData.validUserName=true;
    this.userformData.validRegion=true;
    this.selectedRegion =[];

  }
  onRowEditInit(rowData:any) {
    let roleObj = this.user_roles_master.filter((r:any) => r.label == rowData.role_name)[0];
    this.userformData.editing = true;
    this.userformData.user_name = rowData.user_name;
    this.userformData.user_id = rowData.user_id;
    this.userformData.first_name = rowData.first_name;
    this.userformData.last_name = rowData.last_name;
    this.userformData.email = rowData.email;
    this.userformData.role_id = roleObj.value;
    this.userformData.is_active = rowData.is_active;
    this.userformData.is_admin = rowData.is_admin;
    
    let data:any = rowData.region_master;
    this.selectedRegion=[];
    data.forEach((element:any)=>
      {
        let resultdata=new DropDownMaster();
        resultdata.value=Number(element.masterId);
      
        resultdata.label=element.masterValue;
        this.selectedRegion.push(resultdata.value);
      })     
  }

  saveUser() {
    this.spinner.show();
    this.userformData=this.userModel.validateUserForm(this.userformData);
    if (!(this.userformData.validUserName && this.userformData.validFirstName && this.userformData.validLastName && this.userformData.validUserEmailId && this.userformData.validRole)) {
      this.spinner.hide();
      return false;
    }
    else{
    this.userformData.modified_by=this.loggedinUserinfo.userId;
    if(this.selectedRegion != undefined || this.selectedRegion != null || this.selectedRegion.length>0){
     this.userModel.onSaveUserData(this.userformData,this.selectedRegion).subscribe({
      next:saveResponse=>
      {
        console.error(saveResponse);

          this.toast.success(saveResponse, "Success");
          this.onCancel();
          this.retrieveUserCollection();
          this.spinner.hide();
      }
      ,error:error=>
      {
        this.selectedRegion =[];
        this.toast.error(error, "Error!");
        console.error(error);
        this.spinner.hide();
      }
      
      
    });

  }
else{
  this.toast.error("Region Selection is Mandatory", "Error!");
}
return true;
    }
}
} 
