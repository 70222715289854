import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { DryTruckRequestFormData } from "src/app/requests/data/formdata/dry-request-form.data";
import { PlantCodeMasterData, RequestMasterData } from "src/app/requests/data/masterdata/request-master.data";
import { DryTruckRequestFormModel } from "src/app/requests/dry-request-form.model";
import { DryTruckRequestMasterModel } from "src/app/requests/dry-request-master.model";
import { RequestFormModel } from "src/app/requests/request-form.model";
import { RequestMasterModel } from "src/app/requests/request-master.model";
import { ProductModel } from "src/app/shared/product/products.model";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { CommonRequestComponent } from "../../request-form-common.component";
import { AppServiceService } from "src/app/services/app-service.service";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { MultipleDestination } from "src/app/requests/data/formdata/liquid-request-form.data";



@Component(
  {
    selector: 'app-dry-request-na-form',
    templateUrl: '..\\..\\..\\shared-templates\\request-form-template.html',
    styleUrls: ['..\\..\\..\\shared-templates\\request-form-template.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel, ProductModel,
      { provide: RequestFormModel, useClass: DryTruckRequestFormModel },
      { provide: RequestMasterModel, useClass: DryTruckRequestMasterModel }

    ]
  }
)

export class DryRequestFormNAComponent extends CommonRequestComponent implements OnInit,OnDestroy
 {
 
  private subscriptionName: Subscription; //important to create a subscription
  messageReceived: any;
  override requestData:any;
  override requestMaster:any;

    //override requestMaster: RequestMasterData= new RequestMasterData;
    // requestMaster_backup:RequestMasterData = new RequestMasterData;
    //override requestData: DryTruckRequestFormData = new DryTruckRequestFormData;
    _translate:TranslateService;
    override is_SpecialReq_fileUpload :boolean=false;
    requestMaster_backup:any;
    _toast: ToastrService
    pageLanguage:string="";
    constructor(userAccessModel: LoggedInUserInfoModel, masterModel: RequestMasterModel,
      private productModel: ProductModel, requestModel: RequestFormModel,
      toast: ToastrService, router: Router, spinner: NgxSpinnerService,translate:TranslateService,
       private spinners: NgxSpinnerService, route: ActivatedRoute,private _appServ: AppServiceService) {
      super(userAccessModel, masterModel, requestModel, router, toast, spinner,route,translate);
      this._toast=toast;
      this._translate=translate;
       this.subscriptionName= this._appServ.getUpdate().subscribe(message => { //message contains the data sent from service
      this.messageReceived = message;
      
      let newLang=sessionStorage.getItem('selectedLanguage');

      if(this.pageLanguage!=newLang && this.pageLanguage!=""){
        this.ngOnInit();
      }
      });
    }
    ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
      this.subscriptionName.unsubscribe();
  }
  
  
  
  
    ngOnInit(): void {
      this.pageLanguage=sessionStorage['selectedLanguage'];
      this.startDateLable="START_DATE";
      this.transportType = 2;
      this.requestMaster = new RequestMasterData();
      this.plantCodeShippingDetails=new PlantCodeMasterData();
      this.requestData = new DryTruckRequestFormData();
      this.requestData.transportType = 2;
      this.bindfiledVisiblity();
      this.retrieveHeaderValues();
      //login
      this.validateloggedInUser();
      //Request Master
      this.retrieveMasterData(this.transportType);
      //plantcode with shipping data
      this.retrievePlantCodeMasterData(this.transportType);
      //Article
      // this.retrieveArticleList(this.spinners)
      //retrieve Query Parameters
      this.retrieveQueryPramaters();
      this.retriveLang(this.pageLanguage);
    }
    public clearForm() {
      this.OnCancel();
      }
    bindfiledVisiblity()
    {
      this.fieldVisiblity.requestType=true;
      this.fieldVisiblity.startDate=true;
      this.fieldVisiblity.requestor=true;
      this.fieldVisiblity.bussinessunit=true;
      this.fieldVisiblity.articleNumber=true;
      this.fieldVisiblity.product=true;
      this.fieldVisiblity.mfg=true;
      this.fieldVisiblity.mfgDropdown=true;
      this.fieldVisiblity.freightClass=true;
      this.fieldVisiblity.cleaninCost=true;
      this.fieldVisiblity.interModel=false;
      this.fieldVisiblity.naCountry = true;

  
      // Service Provider
      this.fieldVisiblity.volumeType = true;
      this.fieldVisiblity.annualVolume=true;
      this.fieldVisiblity.nitrogenBlanket=true;
      this.fieldVisiblity.priorProductRestriction=true;
      this.fieldVisiblity.dropTrail=true;
      this.fieldVisiblity.hazardous=true;
      this.fieldVisiblity.vacuumTrailer=true;      
      this.fieldVisiblity.cleaningProcedures=false;
      this.fieldVisiblity.mandDocsInsideTruck=false;
      this.fieldVisiblity.averageProduct=false;

      this.fieldVisiblity.monthlyVolume=false;
      this.fieldVisiblity.materialTopLoad=false;
      this.fieldVisiblity.loadingHrs=false;
      this.fieldVisiblity.unloadingHrs=false;
      this.fieldVisiblity.weekendDeliveryYN=false;    
  
      //Shipping->Orgin
      this.fieldVisiblity.originPlant=true;
      this.fieldVisiblity.shippingName=true;
      this.fieldVisiblity.orginCity=true;
      this.fieldVisiblity.orginState=true;
      this.fieldVisiblity.orginCountry=true;
      this.fieldVisiblity.orginZipCode=true;
      //shipping-> destination
      this.fieldVisiblity.destinationPlant=true;
      this.fieldVisiblity.consigneeName=true;
      this.fieldVisiblity.destinationCity=true;
      this.fieldVisiblity.destinationState=true;
      this.fieldVisiblity.destinationCountry=true;
      this.fieldVisiblity.destinationZipCode=true;
  
      //Additional Info
      this.fieldVisiblity.customerPreferedCarrier=false;
      this.fieldVisiblity.costVsService=false;
      this.fieldVisiblity.comments=true;
  
      this.fieldVisiblity.materialDoc=true;
      this.fieldVisiblity.materialLink=true;
      this.fieldVisiblity.sdsLink=true;
      this.fieldVisiblity.sdsAttachment=true;
      this.fieldVisiblity.additionalAttachment=true;
      this.fieldVisiblity.additionalAttachment2=true;
      
    }

    override onCountryCheck(event:any){
      if(event.value=="Mexico"){
        this.requestMaster_backup=JSON.parse(JSON.stringify(this.requestMaster));
        this.requestMaster.unitMaster=this.requestMaster.unitMaster.filter((u:any)=>u.label!='LIBRAS'&& u.label!='LBS');  
        this.fieldVisiblity.dropTrail=false;
        this.fieldVisiblity.cleaninCost=false;
        this.fieldVisiblity.typeOfShipment=true;
        this.fieldVisiblity.totalWeightShipment=true;
        this.fieldVisiblity.isSpecialRequirement=true;
        this.fieldVisiblity.equipmentType=true;
        this.requestData.multipleDestination=[];
        this.requestData.multipleDestination.push(new MultipleDestination());
        this.fieldVisiblity.multipleDestination=true;
      }
      else{
        this.requestMaster.unitMaster=this.requestMaster_backup?this.requestMaster_backup.unitMaster:this.requestMaster.unitMaster;
        this.requestData.typeOfShipment="";
        this.requestData.monthlyKms=0;
        this.requestData.totalWeightShipment=0;
        this.requestData.totalWeightShipmentUid=0;
  
        this.fieldVisiblity.monthlyKms=false;
        this.fieldVisiblity.dropTrail=true;
        this.fieldVisiblity.cleaninCost=true;
        this.fieldVisiblity.typeOfShipment=false;      
        this.fieldVisiblity.totalWeightShipment=false;
        this.fieldVisiblity.isSpecialRequirement=false;
        this.fieldVisiblity.equipmentType=false;
        this.requestData.multipleDestination=[];
        this.fieldVisiblity.multipleDestination=false;
      }
    }
  
    override onShipmentTypeChange(event:any){
      if(event.target.value=="Dedicated"){
        this.fieldVisiblity.monthlyKms=true;
      }
      else{
        this.fieldVisiblity.monthlyKms=false;
        this.requestData.monthlyKms=0;
      }    
    }
  
    onSpecialRequirement(event:any){
      if(event.target.value=="Yes"){
        this.fieldVisiblity.specailRequirementAttachment=true;
      } 
      else{
        this.fieldVisiblity.specailRequirementAttachment=false;
        this.OnRemoveSpecialReqAttachment();
      }   
    }

    
  override OnSpecialReqFileUpload(event:any){
    this.spinners.show();

    let fileData = event.files[0];
    const element = event.files[0];
    if (element.size > 3145728) {
      this.spinners.hide();
      this._toast.warning(this._translate.instant("FILE_LIMIT_EXCEEDED"));
      return false;
    } else {
      this.requestData.specialRequirementFileName = fileData.name;
      this.requestData.specialRequirementFileExtension = fileData.type;
      this.requestData.specialRequirementAttachment = "";
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.requestData.specialRequirementAttachment = fileReader.result;
        this.requestData.specialRequirementAttachment = this.requestData.specialRequirementAttachment.replace(`data:${this.requestData.specialRequirementFileExtension};base64,`, '');
        this.is_SpecialReq_fileUpload = true;
      }
      fileReader.readAsDataURL(fileData);
      this.spinners.hide();
      return true;
    }

  }

  override OnRemoveSpecialReqAttachment() {
    this.requestData.specialRequirementAttachment = "";
    this.requestData.specialRequirementFileName = "";
    this.requestData.specialRequirementFileExtension = "";
    this.is_SpecialReq_fileUpload = false;
  }
  
    override onArticleClear(event:any){
      this.resetArticleData();
    }
  
    onArticleComplete(event:any){
      super.retrieveProductDetails(event)
    }
    onArticleSelected(event:any){
      this.processArticleNumber(event);
  
    }
  
    retrieveHeaderValues() {
      this.title = "Dry Truck";
      this.description = "A \"Rush Request\" defined as Procurement providing rates to BU after 2 business days otherwise Procurement will provide rates within 7 business days for standard requests.";
    }
    resetArticleData() {
      this.requestData.mfg = "";
      this.selectedMFGCode = "";
      this.requestData.freighClass = "";
      this.requestData.cleaningCost = "";
      this.selectedArticle = [];
      this.requestData.product = "";
      this.requestData.articleNumber = "";
      this.requestMaster.mfgOpts=[];
      this.mappedMfgArticleList=[];
    }
    GenerarateArticleNumberEvent() {
      this.selectedArticle.label = this.requestData.articleNumber;
      return {
        label: this.requestData.articleNumber,
        value: this.requestData.product
      };
    }
  
    handlehazardousChange(event:any)
    {
      console.log("")
    }
    processArticleNumber(event:any){
      this.spinners.show();
      this.requestData.articleNumber = event.label;
      this.requestData.product=event.value;
      this.productModel.retrieveProductForArticle(event.label).subscribe({
        next:result => {
          this.mappedMfgArticleList=result;
          this.requestMaster.mfgOpts=result.map((mfg:any)=>{return {label:`${mfg.mfgCode},${mfg.mfg}`,value:mfg.mfgCode}});
          if(this.requestMaster.mfgOpts.length==1){
            this.selectedMFGCode=this.requestMaster.mfgOpts[0].value;
            this.onMFGChange({value:this.requestMaster.mfgOpts[0].value});
          }
          this.spinners.hide();
        },
        error:error => {
          this._toast.error(this._translate.instant(error),this._translate.instant("ERROR"))
          this.spinners.hide();
        }
        
      });
      
    }
  
    onMFGChange(event:any){
      let mfgSelected=this.mappedMfgArticleList.find(mfg=>mfg.mfgCode==event.value);
      this.requestData.mfg=mfgSelected?.mfg;
      this.requestData.freighClass=mfgSelected?.friegeClass;
      this.requestData.cleaningCost=mfgSelected?.newRate;      
    }
  
    override OnCancel() {
      
      this.clearData(2);
      this.requestData.articleNumber="";
      this.requestData.cleaningCost="";
      this.requestData.freighClass="";
      this.requestData.mfg="";
      this.requestData.campaignSeasonalVolume=0;
      this.requestData.naCountry="";
      this.requestData.typeOfShipment="";
      this.requestData.monthlyKms=0;
      

    //Service provider
    this.requestData.totalWeightShipment=0;
    this.requestData.totalWeightShipmentUid=0;
    this.requestData.days=0
    this.requestData.dropTrail="";
    this.requestData.monthlyVolume=0;
    this.requestData.monthlyVolumeUnitId=0;
    this.requestData.hazardous="";
    this.requestData.loadingHours='';
    this.requestData.materialTopLoad="";
    this.requestData.nitrogenBlanket="";
    this.requestData.unloadingHour='';
    this.requestData.vaccumeTrailer="";
    this.requestData.priorProductRestriction="";
    this.requestData.equipmentTypeId=0;
    this.requestData.specificEquipmentTypeId=0;
    this.requestData.isSpecialRequirement="";
    this.requestData.specialRequirementAttachment="";
    this.requestData.specialRequirementFileName="";
    this.requestData.specialRequirementFileExtension="";
    this.requestData.multipleDestination=[];
    
    this.fieldVisiblity.equipmentType=false;
    this.fieldVisiblity.typeOfShipment=false;
    this.fieldVisiblity.monthlyKms=false;
    this.fieldVisiblity.totalWeightShipment=false;
    this.fieldVisiblity.isSpecialRequirement=false;
    this.fieldVisiblity.specailRequirementAttachment=false;
    this.is_SpecialReq_fileUpload=false;
    this.fieldVisiblity.multipleDestination=false;
    this.fieldVisiblity.cleaninCost=true;
    this.fieldVisiblity.dropTrail=true;
    this.resetArticleData();
    }

    onSubmitRequest() {
      this.handlingDefaultDropdownValues();
      this.requestData.equipmentTypeId=this.requestData.equipmentTypeId??0;
      this.requestData.annualVolumeUnitId=this.requestData.annualVolumeUnitId??0;
      this.requestData.campaignSeasonalVolumeUid=this.requestData.campaignSeasonalVolumeUid??0;
  
      this.createRequest();
    } 

    requestTypeChangeEvent(event:any){
      console.log('');
    }
    onAnnualVolumeChange(event: any){
      console.log('');
    }

    override onMultipleDestinationAdd(){
      //first validation to check if the current destination fields are completed
      let validAdd:boolean=true;
      this.requestData.multipleDestination.forEach((dest:any)=>{
        if(dest.multipleDestinationConsigneeName=="" || dest.multipleDestinationCity=="" ||
            dest.multipleDestinationState==""){
              validAdd=false;
            }
      })
      if(validAdd){
        this.requestData.multipleDestination.push(new MultipleDestination());
      }
      else{
        this._toast.warning(this._translate.instant("Please enter all the mandatory destination fields"),this._translate.instant("WARNING"));
      }
    }
  
   override  onMultipleDestinationRemove(index:number){
      this.requestData.multipleDestination.splice(index,1);
    }

    bindConditionalFields(){
      let dryReqData=this.requestData;
      if(dryReqData.naCountry=="Mexico"){
        this.requestMaster_backup=JSON.parse(JSON.stringify(this.requestMaster));
        this.requestMaster.unitMaster=this.requestMaster.unitMaster.filter((u:any)=>u.label!='LIBRAS' && u.label!='LBS');
        this.fieldVisiblity.cleaninCost=false;
        this.fieldVisiblity.dropTrail=false;
        this.fieldVisiblity.typeOfShipment=true;
        this.fieldVisiblity.monthlyKms=dryReqData.typeOfShipment=="Dedicated";
        this.fieldVisiblity.totalWeightShipment=true;
        this.fieldVisiblity.isSpecialRequirement=true;
        this.fieldVisiblity.specailRequirementAttachment=dryReqData.isSpecialRequirement=="Yes";
        this.fieldVisiblity.equipmentType=true;
        this.fieldVisiblity.multipleDestination=true;
        if (dryReqData.specialRequirementFileName != undefined && dryReqData.specialRequirementFileName != "") {
          this.is_SpecialReq_fileUpload = true;
        }
        this.processMultipleDestinationFields();
      }
      else{
        this.fieldVisiblity.cleaninCost=true;
        this.fieldVisiblity.dropTrail=true;

        this.fieldVisiblity.typeOfShipment=false;
        this.fieldVisiblity.monthlyKms=false;
        this.fieldVisiblity.totalWeightShipment=false;
        this.fieldVisiblity.isSpecialRequirement=false;
        this.fieldVisiblity.specailRequirementAttachment=false;
        this.fieldVisiblity.equipmentType=false;
        this.fieldVisiblity.multipleDestination=false;
      }
    }
    onPlantSelectPopulate(event:any,index:number){
      let addDetails=this.plantCodeShippingDetails.plantShippingData.find(plant=>plant.masterId==event.value);
  
      this.requestData.multipleDestination[index].multipleDestinationPlantId=this.requestData.multipleDestination[index].selectedDestinationPlantCode.value;
      this.requestData.multipleDestination[index].multipleDestinationConsigneeName=addDetails!.masterName;
      this.requestData.multipleDestination[index].multipleDestinationCity=addDetails!.city;
      this.requestData.multipleDestination[index].multipleDestinationState=addDetails!.state;
      this.requestData.multipleDestination[index].multipleDestinationZipCode=addDetails!.zip;
      this.requestData.multipleDestination[index].multipleDestinationCountry=this.requestMaster.countryMaster.find((co:any)=>co.label==addDetails!.country)?.value;
    }

    processMultipleDestinationFields(){
      this.requestData.multipleDestination.forEach((dest:any)=>{
        dest.destinationPlantMode=true;
        dest.selectedDestinationPlantCode={label:dest.multipleDestinationPlantCode}
      })
    }

    override resetMultipleDestinationPlantCodeData(index:number){
      this.requestData.multipleDestination[index].multipleDestinationPlantId=0;
      this.requestData.multipleDestination[index].multipleDestinationConsigneeName="";
      this.requestData.multipleDestination[index].multipleDestinationCity="";
      this.requestData.multipleDestination[index].multipleDestinationState="";
      this.requestData.multipleDestination[index].multipleDestinationZipCode="";
      this.requestData.multipleDestination[index].multipleDestinationCountry="";

    }  
  }