import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoggedInUserInfoModel } from "src/app/site-permissions/loggedin-user-access.model";
import { LiquidTruckRequestFormData, MultipleDestination } from "../../../data/formdata/liquid-request-form.data";
import { PlantCodeMasterData, RequestMasterData } from "../../../data/masterdata/request-master.data";
import { LiquidTruckRequestMasterModel } from "../../../liquid-request-master.model";
import { RequestMasterModel } from "../../../request-master.model";
import { CommonRequestComponent } from "../../request-form-common.component";
import { ProductModel } from "src/app/shared/product/products.model";
import { RequestFormModel } from "../../../request-form.model";
import { LiquidTruckRequestFormModel } from "../../../liquid-request-form.model";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";


@Component(
  {
    selector: 'app-liquid-request-emea-form',
    templateUrl: '..\\..\\..\\shared-templates\\request-form-template.html',
    styleUrls: ['..\\..\\..\\shared-templates\\request-form-template.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [LoggedInUserInfoModel, ProductModel,
      { provide: RequestFormModel, useClass: LiquidTruckRequestFormModel },
      { provide: RequestMasterModel, useClass: LiquidTruckRequestMasterModel }

    ]
  }
)

export class LiquidRequestFormEMEAComponent extends CommonRequestComponent implements OnInit {


  // override requestMaster: RequestMasterData = new RequestMasterData;
  // override requestData: LiquidTruckRequestFormData = new LiquidTruckRequestFormData;
  override requestData:any;
  override requestMaster:any;
  _toast: ToastrService;
  _translate:TranslateService

  constructor(userAccessModel: LoggedInUserInfoModel, masterModel: RequestMasterModel,
    private productModel: ProductModel, requestModel: RequestFormModel,
    toast: ToastrService, router: Router, spinner: NgxSpinnerService,translate:TranslateService,
    private spinners: NgxSpinnerService, route: ActivatedRoute) {
    super(userAccessModel, masterModel, requestModel, router, toast, spinner, route,translate);
    this._toast = toast;
    this._translate=translate;
  }




  ngOnInit(): void {
    this.transportType = 1;
    this.startDateLable = "START_DATE";

    this.requestMaster = new RequestMasterData();
    this.plantCodeShippingDetails=new PlantCodeMasterData();
    this.requestData = new LiquidTruckRequestFormData();
    this.requestData.multipleDestination.push(new MultipleDestination())
    this.requestData.transportType = 1;
    this.bindfiledVisiblity();
    this.retrieveHeaderValues();
    //login
    this.validateloggedInUser();
    //Request Master
    this.retrieveMasterData(this.transportType);
       //plantcode with shipping data
    this.retrievePlantCodeMasterData(this.transportType);
    //Article
    //this.retrieveArticleList(this.spinners)
    //retrieve Query Parameters
    this.retrieveQueryPramaters();
    this.retriveLang('English');

  }
  bindfiledVisiblity() {
    this.isDropdownClear = true;

    this.fieldVisiblity.requestType = true;
    this.fieldVisiblity.startDate = false;
    this.fieldVisiblity.requestor = true;
    this.fieldVisiblity.bussinessunit = true;
    this.fieldVisiblity.articleNumber = true;
    this.fieldVisiblity.articleNumberMandatory = true;
    this.fieldVisiblity.product = true;
    this.fieldVisiblity.mfg = true;
    this.fieldVisiblity.mfgDropdown = true;
    this.fieldVisiblity.freightClass = false;
    this.fieldVisiblity.cleaninCost = false;
    this.fieldVisiblity.interModel = false;
    this.fieldVisiblity.equipmentType = false;

    // Service Provider
    this.fieldVisiblity.volumeType = true;
    this.fieldVisiblity.annualVolume=true;
    this.fieldVisiblity.monthlyVolume = false;
    this.fieldVisiblity.centerRearUnload = false;
    this.fieldVisiblity.centerRearUnloadMandatory = false;
    this.fieldVisiblity.dropTrail = false;
    this.fieldVisiblity.days = false;
    this.fieldVisiblity.tempRange = false;
    this.fieldVisiblity.tempRequirement = false;
    this.fieldVisiblity.priorProductRestriction = false;
    this.fieldVisiblity.hazardousMandatory = false;
    this.fieldVisiblity.kosher = false;
    this.fieldVisiblity.materialTopLoad = false;
    this.fieldVisiblity.foodGradeWash = false;
    this.fieldVisiblity.loadingHrs = true;
    this.fieldVisiblity.unloadingHrs = false;
    this.fieldVisiblity.nitrogenBlanket = false;
    this.fieldVisiblity.weekendDeliveryYN=false
    this.fieldVisiblity.groundVapourRecovery = false;
    this.fieldVisiblity.centerRear = false;
    this.fieldVisiblity.pump = true;
    this.fieldVisiblity.chassis = true;
    this.fieldVisiblity.couplings = true;
    this.fieldVisiblity.loadingPoint =false;
    this.fieldVisiblity.gmp = false;
    this.fieldVisiblity.fami = false;
    this.fieldVisiblity.isopa = false;
    this.fieldVisiblity.halalWash = false;
    this.fieldVisiblity.unloadingHose = false;
    this.fieldVisiblity.onboardCompressor = false;
    this.fieldVisiblity.others = true;
    this.fieldVisiblity.specificEquipmentType=true;

    //Shipping->Orgin
    this.fieldVisiblity.originPlant = true;
    this.fieldVisiblity.shippingName = true;
    this.fieldVisiblity.orginCity = false;
    this.fieldVisiblity.orginCityEMEA = true;
    this.fieldVisiblity.orginState = false;
    this.fieldVisiblity.orginCountry = false;
    this.fieldVisiblity.orginCountryEMEA = true;
    this.fieldVisiblity.orginZipCode = false;
    this.fieldVisiblity.originZipCodeMandatory=true;
    //shipping-> destination
    this.fieldVisiblity.multipleDestination=true;
    this.fieldVisiblity.destinationPlant = false;
    this.fieldVisiblity.consigneeName = true;
    this.fieldVisiblity.destinationCity = false;
    this.fieldVisiblity.destinationCityEMEA = true;
    this.fieldVisiblity.destinationState = false;
    this.fieldVisiblity.destinationCountry = false;
    this.fieldVisiblity.destinationCountryEMEA = true;
    this.fieldVisiblity.destinationZipCode = false;
    
    this.fieldVisiblity.distinationZipCodeMandatory=true;

    //Additional Info
    this.fieldVisiblity.customerPreferedCarrier = false;
    this.fieldVisiblity.costVsService = false;
    this.fieldVisiblity.comments = true;

    this.fieldVisiblity.materialDoc = true;
    this.fieldVisiblity.materialLink = true;
    this.fieldVisiblity.sdsLink = true;
    this.fieldVisiblity.sdsAttachment = true;
    this.fieldVisiblity.is_sds_fileUpload_mandatory =true;
    this.fieldVisiblity.additionalAttachment = true;
    this.fieldVisiblity.additionalAttachment2 = true;
    this.fieldVisiblity.preferedTransportMode=true;
    this.fieldVisiblity.heatingDuringTransport=true;
    this.fieldVisiblity.groundOperatingEquipment=true;
    this.fieldVisiblity.specialCertification=true;
    this.fieldVisiblity.hoseLength=true;
    this.fieldVisiblity.driverLanguage=true;
    this.fieldVisiblity.requiredCleaningProcedure=true;
    this.fieldVisiblity.vacuumTestConfirmation=true;
    this.fieldVisiblity.reactorLoading=true;
    this.fieldVisiblity.twoStepBusiness=true;
    this.fieldVisiblity.onlyOnecompartments=true;
  }
  onAnnualVolumeChange(event: any){
    console.log("");
   

  }
  override onArticleClear(event:any){
    this.resetArticleData();
  }

  onArticleComplete(event:any){
    super.retrieveProductDetails(event)
  }
  onArticleSelected(event:any){
    this.processArticleNumber(event);

  }

  retrieveHeaderValues() {
    this.title = "Liquid Truck";
    this.description = "A \"Rush Request\" defined as Procurement providing rates to BU after 2 business days otherwise Procurement will provide rates within 7 business days for standard requests.";
  }
  resetArticleData() {
    this.requestData.mfg = "";
    this.selectedMFGCode = "";
    this.requestData.freighClass = "";
    this.requestData.cleaningCost = "";
    this.selectedArticle = [];
    this.requestData.product = "";
    this.requestData.articleNumber = "";
    this.requestMaster.mfgOpts=[];
    this.mappedMfgArticleList=[];

  }
  GenerarateArticleNumberEvent() {
    this.selectedArticle.label = this.requestData.articleNumber;
    return {
      label: this.requestData.articleNumber,
      value: this.requestData.product
    };
  }
  handlehazardousChange(event:any){
    console.log("");

  }
  processArticleNumber(event:any){
    this.spinners.show();
    this.requestData.articleNumber = event.label;
    this.requestData.product=event.value;
    console.log("label-",event.label," value-",event.value);
    this.productModel.retrieveProductForArticle(event.label).subscribe({
      next:result => {
        this.mappedMfgArticleList=result;
        this.requestMaster.mfgOpts=result.map((mfg:any)=>{return {label:`${mfg.mfgCode},${mfg.mfg}`,value:mfg.mfgCode}});
        if(this.requestMaster.mfgOpts.length==1){
          this.selectedMFGCode=this.requestMaster.mfgOpts[0].value;
          this.onMFGChange({value:this.requestMaster.mfgOpts[0].value});
        }
        this.spinners.hide();
      },
      error:error => {
        this._toast.error(this._translate.instant(error),this._translate.instant("ERROR"))
        this.spinners.hide();
      }
      
    });
    
  }

  onMFGChange(event:any){
    let mfgSelected=this.mappedMfgArticleList.find(mfg=>mfg.mfgCode==event.value);
    this.requestData.mfg=mfgSelected?.mfg;    
  }

  public clearForm() {
    this.OnCancel();
  }
  override OnCancel() {

    this.clearData(1);
    this.requestData.articleNumber = "";
    this.requestData.cleaningCost = "";
    this.requestData.freighClass = "";
    this.requestData.internModel = "";
    this.requestData.mfg = "";
    this.requestData.equipmentTypeId = 0;

    //Service provider
    this.requestData.days = 0;
    this.requestData.dropTrail = "";
    this.requestData.monthlyVolume = 0;
    this.requestData.monthlyVolumeUnitId = 0;
    this.requestData.centerRear = "";
    this.requestData.centerRearUnload = "";
    this.requestData.foodGradeWash = "";
    this.requestData.groundVapourDelivery = "";
    this.requestData.hazardous = "";
    this.requestData.kosher = "";
    this.requestData.loadingHours = ''
    this.requestData.materialTopLoad = "";
    this.requestData.nitrogenBlanket = "";
    this.requestData.priorProductRestriction = "";
    this.requestData.tempRange = "";
    this.requestData.tempRequirementId = 0;
    this.requestData.unloadingHour = '';
    this.requestData.weekendDelivery = "";
    this.requestData.pumpId=0;
    this.requestData.chassisId=0;
    this.requestData.couplings="";
    this.requestData.loadingPointId=0;
    this.requestData.gmp="";
    this.requestData.fami="";
    this.requestData.isopa="";
    this.requestData.halalWash="";
    this.requestData.unloadingHose="";
    this.requestData.onboardCompressor="";
    this.requestData.others="";
    this.requestData.multipleDestination=[];
    this.requestData.multipleDestination.push(new MultipleDestination());
    this.resetArticleData();

    this.requestData.preferedTransportModeId=0;
    this.requestData.specialCertificationId=0;
    this.requestData.specificEquipmentTypeId=0;
    this.requestData.pump="";
    this.requestData.hoseLengthId=0;
    this.requestData.driverLanguageId=0;
    this.requestData.requiredCleaningProcedureId=0;
    this.requestData.vacuumTestConfirmation="";
    this.requestData.reactorLoading="";
    this.requestData.heatingDuringTransport="";
    this.requestData.groundOperatingEquipment="";
    this.requestData.twoStepBusiness="";
    this.requestData.onlyOnecompartments="";
  }

  onSubmitRequest() {
    this.handlingDefaultDropdownValues();
    const defaultValues = [
      'equipmentTypeId',
      'annualVolumeUnitId',
      'chassisId',
      'loadingPointId',
      'preferedTransportModeId',
      'heatingDuringTransportId',
      'groundOperatingEquipmentId',
      'specialCertificationId',
      'specificEquipmentTypeId',
      'hoseLengthId',
      'driverLanguageId',
      'requiredCleaningProcedureId',
      'vacuumTestConfirmationId'
    ];

    defaultValues.forEach(field => {
        this.requestData[field] = this.requestData[field] ?? 0;
    });
    if(this.requestData.startDate==null || this.requestData.startDate=="" || this.requestData.startDate==undefined) {
      this.requestData.startDate=moment(new Date()).format('YYYY-MM-DD')
    }
    
    this.requestData.monthlyVolume = this.requestData.monthlyVolume ?? 0;
    this.requestData.annualVolume = this.requestData.annualVolume ?? 0;
    this.requestData.days = this.requestData.days ?? 0;
    this.validateMasterData();
    this.createRequest();
  }

  validateMasterData(){
    if(this.requestData.tempRequirementId!=0
      && !this.requestMaster.tempRequirementMaster.find((t:any)=>t.value==this.requestData.tempRequirementId))
    {
     this.requestData.tempRequirementId=0;
    }
  }

  requestTypeChangeEvent(event:any){
    console.log("");
  }

  bindConditionalFields(){
    if(this.editMode)
    this.processMultipleDestinationFields();

  }
  onPlantSelectPopulate(event:any,index:number){
    let addDetails=this.plantCodeShippingDetails.plantShippingData.find(plant=>plant.masterId==event.value);

    this.requestData.multipleDestination[index].multipleDestinationPlantId=this.requestData.multipleDestination[index].selectedDestinationPlantCode.value;
    this.requestData.multipleDestination[index].multipleDestinationConsigneeName=addDetails!.masterName;
    this.requestData.multipleDestination[index].multipleDestinationCity=addDetails!.city;
    this.requestData.multipleDestination[index].multipleDestinationState=addDetails!.state;
    this.requestData.multipleDestination[index].multipleDestinationZipCode=addDetails!.zip;
    this.requestData.multipleDestination[index].multipleDestinationCountry=this.requestMaster.countryMaster.find((co:any)=>co.label==addDetails!.country)?.value;
  }

  processMultipleDestinationFields(){
    this.requestData.multipleDestination.forEach((dest:any)=>{
      dest.destinationPlantMode=true;
      dest.selectedDestinationPlantCode={label:dest.multipleDestinationPlantCode}
    })
  }
  override onMultipleDestinationAdd(){
    //first validation to check if the current destination fields are completed
    let validAdd:boolean=true;
    this.requestData.multipleDestination.forEach((dest:any)=>{
      if(dest.multipleDestinationConsigneeName=="" || dest.multipleDestinationCity=="" ){
            validAdd=false;
          }
    })
    if(validAdd){
      this.requestData.multipleDestination.push(new MultipleDestination());
    }
    else{
      this._toast.warning(this._translate.instant("MEXICO_DESTINATION_MANDATE_VAL_REQUIRED"),this._translate.instant("WARNING"));
    }
  }

  override  onMultipleDestinationRemove(index:number){
    this.requestData.multipleDestination.splice(index,1);
  }

  override resetMultipleDestinationPlantCodeData(index:number){
    this.requestData.multipleDestination[index].multipleDestinationPlantId=0;
    this.requestData.multipleDestination[index].multipleDestinationConsigneeName="";
    this.requestData.multipleDestination[index].multipleDestinationCity="";
    this.requestData.multipleDestination[index].multipleDestinationState="";
    this.requestData.multipleDestination[index].multipleDestinationZipCode="";
    this.requestData.multipleDestination[index].multipleDestinationCountry="";

  }  
  onSpecialRequirement(event:any){  }
}
